.tableWrapper {
    overflow-x: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.tableWrapper > div {
    overflow-y: auto;
    max-height: calc(100vh - 50px); /* убедитесь, что высота расчета правильная */
    margin-top: 0;
}

.table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Делаем таблицу фиксированной по ширине */
}

thead th {
    position: sticky;
    top: 0;
    background-color: #4d4d59;
    z-index: 2;
}